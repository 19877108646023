import * as React from "react";

const SvgIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1359 21.545C25.1959 21.6201 25.301 21.53 25.256 21.4549L18.3216 9.44691L17.0158 7.18039C16.9557 7.09033 16.8657 7.07532 16.8206 7.07532C16.7756 7.07532 16.6856 7.10533 16.6555 7.21041L14.7644 13.5146C14.4642 14.5053 15.2146 15.496 16.2353 15.496H18.1265C19.2222 15.496 20.2728 15.9763 20.9933 16.8018L25.1359 21.545ZM13.4554 13.924L14.9264 9.01569C14.9564 8.92563 14.8363 8.88061 14.7913 8.95566L6.37098 23.5304C6.31094 23.6355 6.35597 23.7105 6.401 23.7556C6.44603 23.8006 6.52108 23.8456 6.62614 23.8006L6.65616 23.7856L14.1309 21.0387C15.0314 20.7085 15.4067 19.6578 14.9264 18.8323L13.8007 16.911C13.2903 16.0104 13.1552 14.9296 13.4554 13.924ZM26.1431 24.9104H23.9067L7.33625 24.9255C7.24619 24.9255 7.23118 24.8054 7.30623 24.7754L15.1262 21.9084C15.9667 21.5932 16.6571 21.0078 17.1074 20.2423L18.1731 18.3811C18.6984 17.4655 19.9742 17.3454 20.6646 18.1409L26.2932 24.5802C26.365 24.6665 26.3406 24.7389 26.3252 24.7845C26.3245 24.7865 26.3238 24.7885 26.3232 24.7904C26.3082 24.8354 26.2482 24.9104 26.1431 24.9104Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgIcon;
